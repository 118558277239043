<template lang="pug">
.edit-profile
	ActiveOffers
	UserParameters(
		@userParameters="setUserParameters"
		@weight="val => this.setData(val, 'weight')"
		@height="val => this.setData(val, 'height')"
		@description="val => this.setData(val, 'description')"
		@saveData="SendData"
		:widgetsData="widgetsData"
		:errorText="mainErrorText"
	)
	UserInfo(
		@voice="val => {this.setData(val, 'voice')}"
		@setSelfie="setSelfieImage"
		@setImages="setImages"
	)
</template>

<script>
import {useToast} from "vue-toastification";
import tokenService from "../services/token.service";
import {mapGetters} from "vuex";
import {ajax, apiUrls} from "../api/urls";

import ActiveOffers from "../components/home/ActiveOffers";
import UserParameters from "../components/edit-profile/UserParameters";
import UserInfo from "../components/edit-profile/UserInfo";

export default {
	name: "UserProfile",
	components: {UserInfo, UserParameters, ActiveOffers},
	setup() {
		const toast = useToast()
		return {toast}
	},
	data() {
		return {
			addBiographyFormData: null,
			activeProfile: null,
			mainErrorText: '',
			userParametersData: {
				voice: null,
				birthday: "",
				description: "",
				gender: null,
				height: 0,
				weight: 0,
				hair_color: null,
				eye_color: null,
				skin_color: null,
				other_characteristics: []
			},
			widgetDataTemp: [
				{type: 'DSelect', id: 'gender', title: 'სქესი', placeholder: 'ნებისმიერი', value: undefined},
				{type: 'DSelect', id: 'eye_color', title: 'თვალის ფერი', placeholder: 'ნებისმიერი', value: undefined},
				{type: 'DSelect', id: 'skin_color', title: 'კანის ფერი', placeholder: 'ნებისმიერი', value: undefined},
				{type: 'DSelect', id: 'hair_color', title: 'თმის ფერი', placeholder: 'ნებისმიერი', value: undefined},
				{
					type: 'DMultiSelect',
					id: 'characteristics',
					title: 'სხვა მახასიათებლები',
					placeholder: 'ნებისმიერი',
					value: undefined
				},
				{type: 'DDatePickers', id: 'date', title: 'დაბადების თარიღი', singleInput: true, value: undefined},
			]
		}
	},
	computed: {
		...mapGetters({
			userBiographyId: "user/userBiographyId",
			userBiography: "user/userBiography",
			profileData: "user/profileData",
			mainProfileBiographyId: "user/mainProfileBiographyId",
			mainProfileId: "user/mainProfileId"
		}),
		widgetsData: {
			get() {
				return this.widgetDataTemp
			},
			set(val) {
				this.widgetDataTemp = val
			}
		}
	},
	mounted() {
		this.activeProfile = tokenService.getItem('activeProfile') ? Number(tokenService.getItem('activeProfile')) : null
		this.$store.dispatch("posts/fetchFiltersPromise").finally(() => {
			if (this.activeProfile !== null) this.$store.dispatch('user/fetchUserBiography', this.activeProfile)
		})
		this.$store.dispatch("user/fetchActiveOffers")
		this.$store.dispatch("user/userData")
	},
	watch: {
		userBiography(val) {
			// get only ids from other_characteristics
			const other_characteristics_id = val.other_characteristics.map(el => el.id)

			// set server biography data on local data
			this.userParametersData = {
				voice: null,
				birthday: val.birthday,
				description: val.description,
				gender: val.gender,
				height: val.height,
				weight: val.weight,
				hair_color: val.hair_color.id,
				eye_color: val.eye_color.id,
				skin_color: val.skin_color.id,
				other_characteristics: other_characteristics_id
			}

			this.widgetsData = [
				{type: 'DSelect', id: 'gender', title: 'სქესი', placeholder: 'ნებისმიერი', value: val.gender},
				{
					type: 'DSelect',
					id: 'eye_color',
					title: 'თვალის ფერი',
					placeholder: 'ნებისმიერი',
					value: val.eye_color.id
				},
				{
					type: 'DSelect',
					id: 'skin_color',
					title: 'კანის ფერი',
					placeholder: 'ნებისმიერი',
					value: val.skin_color.id
				},
				{
					type: 'DSelect',
					id: 'hair_color',
					title: 'თმის ფერი',
					placeholder: 'ნებისმიერი',
					value: val.hair_color.id
				},
				{
					type: 'DMultiSelect',
					id: 'characteristics',
					title: 'სხვა მახასიათებლები',
					placeholder: 'ნებისმიერი',
					value: other_characteristics_id
				},
				{type: 'DDatePickers', id: 'date', title: 'დაბადების თარიღი', singleInput: true, value: val.birthday},
			]
		}
	},
	methods: {
		// user images
		setSelfieImage(val) {
			let fm = new FormData()
			fm.append('images', val)
			fm.append('is_profile_image', 'true')
			fm.append('biography', this.userBiographyId)
			this.$store.dispatch("user/sendImages", fm)
		},
		setImages(val) {
			let fm = new FormData()
			val.forEach(imageFile => {
				fm.append('images', imageFile)
			})
			fm.append('biography', this.userBiographyId)
			this.$store.dispatch("user/sendImages", fm)
		},
		setData(val, key) {
			this.userParametersData[key] = val
		},
		setUserParameters(val) {
			if (val.filterId === "characteristics") {
				let element = this.userParametersData.other_characteristics?.findIndex(el => el === val.selectedId)
				if (element === -1) {
					this.userParametersData.other_characteristics.push(val.selectedId)
				} else {
					this.userParametersData.other_characteristics?.splice(element, 1)
				}
			} else if (val.filterId === "date") {
				this.userParametersData.birthday = val.value[0]
			} else {
				this.userParametersData[val.filterId] = val.selectedId
			}

			this.widgetDataTemp.forEach(el => {
				if (val.filterId === "characteristics" && el.id === "characteristics") {
					if (el.value === undefined) {
						el.value = []
					}
					let element = el.value.findIndex(el => el === val.selectedId)
					if (element === -1) {
						el.value.push(val.selectedId)
					} else {
						el.value.splice(element, 1)
					}
				} else if (el.id === val.filterId) {
					el.value = val.selectedId
				}
			})
		},
		SendData() {
			this.addBiographyFormData = new FormData()
			// check if user already have biography
			if (this.userBiographyId) {
				// update user biography
				if (this.userParametersData.voice) this.addBiographyFormData.append('voice', this.userParametersData.voice)
				this.addBiographyFormData.append('birthday', this.userParametersData.birthday)
				this.addBiographyFormData.append('description', this.userParametersData.description)
				this.addBiographyFormData.append('gender', this.userParametersData.gender)
				this.addBiographyFormData.append('height', this.userParametersData.height)
				this.addBiographyFormData.append('weight', this.userParametersData.weight)
				this.addBiographyFormData.append('hair_color', this.userParametersData.hair_color)
				this.addBiographyFormData.append('eye_color', this.userParametersData.eye_color)
				this.addBiographyFormData.append('skin_color', this.userParametersData.skin_color)
				this.userParametersData.other_characteristics.forEach(el => {
					this.addBiographyFormData.append('other_characteristics', el)
				})
				ajax.put(apiUrls.singleBiography(this.userBiographyId), this.addBiographyFormData).then(() => {
					this.mainErrorText = ''
					this.toast.success('მონაცემები წარმატებით შეინახა')
				}).catch(err => {
					if (err.response.status === 400) {
						this.mainErrorText = 'გთხოვთ შეიყვანოთ ყველა მონაცემი'
					}
				})
			} else {
				// add new biography
				if ((this.mainProfileId === this.profileData.id) && this.profileData.children !== undefined) {
					this.addBiographyFormData.append('user', this.mainProfileId)
				} else this.addBiographyFormData.append('child', this.profileData.id)

				if (this.userParametersData.voice) this.addBiographyFormData.append('voice', this.userParametersData.voice)
				this.addBiographyFormData.append('birthday', this.userParametersData.birthday)
				this.addBiographyFormData.append('description', this.userParametersData.description)
				this.addBiographyFormData.append('gender', this.userParametersData.gender)
				this.addBiographyFormData.append('height', this.userParametersData.height)
				this.addBiographyFormData.append('weight', this.userParametersData.weight)
				this.addBiographyFormData.append('hair_color', this.userParametersData.hair_color)
				this.addBiographyFormData.append('eye_color', this.userParametersData.eye_color)
				this.addBiographyFormData.append('skin_color', this.userParametersData.skin_color)
				this.userParametersData.other_characteristics.forEach(el => {
					this.addBiographyFormData.append('other_characteristics', el)
				})
				ajax.post(apiUrls.userBiographies, this.addBiographyFormData,
					{
						headers: {"content-type": "multipart/form-data"}
					}).then(res => {
					// fetch user data after biography addition is successful
					this.$store.dispatch('user/userData')
					this.$store.dispatch('user/fetchUserBiography', res.data.id)
					this.toast.success('მონაცემები წარმატებით დაემატა')
					this.mainErrorText = ''
					if (res.data.child) {
						// remove childId
						tokenService.removeItem("activeChildId")
						this.$store.commit('user/setActiveChildId', null)

						// set biography id
						tokenService.setItem("activeProfile", res.data.id)
						this.$store.commit('user/setActiveProfile', res.data.id)
					}
				}).catch(err => {
					if (err.response.status === 400) {
						this.mainErrorText = 'გთხოვთ შეიყვანოთ ყველა მონაცემი'
					}
				})
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.edit-profile {
	display: grid;
	min-height: calc(100vh - 108px);
	grid-template-columns: 380px 1fr 1fr;
	grid-column-gap: 20px;
	padding-top: 50px;
	position: relative;

	:deep(.active-offers) {
		max-height: calc(100vh - 158px);
		height: 100%;

		> h2 {
			margin-bottom: 15px;
		}

		> .active-offers-list {
			height: calc(100% - 34px);
		}
	}

	@media(max-width: 1366px) {
		min-height: 87vh;
	}
}
</style>
