<template lang="pug">
.user-info
    .user-gallery
        h2 ᲒᲐᲚᲔᲠᲔᲐ
        .user-gallery-content
            .user-images
                .user-selfie
                    .img-cont(:class="{'blur-on-hover': profileImage}")
                        img(:src="selfieImageSource")
                        .blur-background
                        .delete-image(@click="deleteImage(profileImage.id, true)")
                            img
                            p წაშლა
                    DefaultImageInput(text2="სელფის ატვირთვა" v-model:value="selfie_image" :disable="disableImageInput" hideDelete)
                .other-images
                    .images
                        .image-item(v-for="(item, index) in userImages" :key="index" :class="{'blur-on-hover': item.id}")
                            img(:src="item.image")
                            .blur-background
                            .delete-image(@click="deleteImage(item.id)")
                                img
                                p წაშლა
                    MultipleImageUploader(
                        text="ფოტოს ატვირთვა"
                        v-model:imagesSrc="imageSources"
                        v-model:imageFiles="imageFiles"
                        :deleteImageIndex="deleteImageIndex"
                        @setDeleteIndex="val => this.deleteImageIndex=val"
                        :disable="disableMultiImageUploader"
                    )
            .user-voice
                InputVoice(@voice="val => this.$emit('voice', val)" :voiceSrc="userBiography.voice")
    .user-contact-information
        h2 ᲡᲐᲙᲝᲜᲢᲐᲥᲢᲝ ᲘᲜᲤᲝᲠᲛᲐᲪᲘᲐ
        .user-contact-information-content
            .first-row
                DefaultInput(type="email" v-model:value="email" text="მეილი" :disabled="true")
                DefaultInput(type="tel" v-model:value="phone_number" text="ტელეფონი" :disabled="true")
            .second-row
                router-link(to="recover-password") პაროლის შეცვლა
                button(@click="changeMode") {{ !editMode ? "ᲠᲔᲓᲐᲥᲢᲘᲠᲔᲑᲐ" : "შენახვა" }}
</template>

<script>
import DefaultInput from "@/components/layout/DefaultInput";
import DefaultImageInput from "@/components/layout/DefaultImageInput";
import MultipleImageUploader from "@/components/layout/MultipleImageUploader";
import InputVoice from "@/components/layout/InputVoice";
import {mapGetters} from "vuex";
import tokenService from "@/services/token.service";

export default {
    name: "UserInfo",
    components: {
        InputVoice,
        MultipleImageUploader,
        DefaultImageInput,
        DefaultInput
    },
    data() {
        return {
            email: "",
            phone_number: "",
            selfie_image: null,
            imageSources: [],
            imageFiles: [],
            deleteImageIndex: null,
            editMode: false
        }
    },
    computed: {
        ...mapGetters({
            userBiography: "user/userBiography",
            profileData: "user/profileData"
        }),
        disableImageInput() {
            const hasBiography = Object.keys(this.userBiography).length > 4
            const profileImage = this.userBiography.images.find(item => item.is_profile_image)
            return !hasBiography ? !hasBiography : !!profileImage
        },
        profileImage() {
            return this.userBiography.images.find(item => item.is_profile_image)
        },
        selfieImageSource() {
            return this.selfie_image ? this.selfie_image.imageSrc[0] :
                this.profileImage ? this.profileImage.image :
                    require("../../assets/images/defaultImage.png")
        },
        userImages() {
            let userImages = []
            this.userBiography.images.forEach(item => {
                if(!item.is_profile_image) userImages.push(item)
            })
            while (userImages.length !== 6) {
                userImages.push({
                    id: null,
                    image: require("../../assets/images/defaultImage.png"),
                    is_profile_image: false
                })
            }
            return userImages
        },
        disableMultiImageUploader() {
            const hasBiography = Object.keys(this.userBiography).length > 4
            let userImages = []
            this.userBiography.images.forEach(item => {
                if(!item.is_profile_image) userImages.push(item)
            })
            return !hasBiography ? !hasBiography : userImages.length === 6
        }
    },
    watch: {
        imageFiles: {
            handler(val) {
                this.$emit('setImages', val)
            },
            deep: true
        },
        selfie_image(nVal) {
            if(nVal) {
                this.$emit('setSelfie', nVal.imageFile)
            }
        },
        profileData: {
            handler({phone_number, email}) {
                if(tokenService.getItem('isChild') !== 'true') {
                    // for main
                    this.phone_number = phone_number
                    this.email = email
                } else {
                    // for child
                    this.phone_number = ""
                    this.email = ""
                }
            },
            deep: true
        }
    },
    methods: {
        deleteImage(imageId, isSelfie) {
            // biography and userdata updated in store
            this.$store.dispatch('user/deleteImage', imageId)
            if(isSelfie) this.selfie_image=null
        },
        changeMode() {
            if (this.editMode) {
                this.$emit('saveData')
            }
            this.editMode = !this.editMode
        }
    }
}
</script>

<style lang="scss" scoped>
.user-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
        font: normal normal bold 16px/19px 'Helvetica-Bold';
        letter-spacing: 0;
        color: #212121;
        margin-bottom: 15px;
    }

    .user-gallery {
        flex-grow: 3;
        margin-bottom: 60px;

        .user-gallery-content {
            width: 100%;
            height: 100%;
            background: #FFFFFF 0 0 no-repeat padding-box;
            box-shadow: 0 3px 20px #00000029;
            border-radius: 5px;
            padding: 20px;
            position: relative;

            .user-images {
                display: flex;
                justify-content: space-between;
                gap: 25px;

                .user-selfie {

                    .img-cont {
                        position: relative;
                        width: 106px;
                        height: 123px;

                        > img {
                            width: 106px;
                            height: 123px;
                            border-radius: 10px;
                            object-fit: cover;
                        }

                        > .blur-background {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 106px;
                            height: 123px;
                            border-radius: 10px;
                            background-color: #FFFFFF;
                            opacity: 0;
                            z-index: 4;
                            transition: .3s;
                        }

                        .delete-image {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            transition: .3s;
                            opacity: 0;
                            z-index: 5;
                            pointer-events: none;

                            p {
                                font: normal normal normal 12px/13px Helvetica;
                                letter-spacing: 0;
                                color: #212121;
                            }
                        }
                    }

                    .img-cont.blur-on-hover:hover > .blur-background {
                        opacity: .5;
                    }

                    .img-cont.blur-on-hover:hover > .delete-image {
                        opacity: 1;
                        pointer-events: auto;
                        cursor: pointer;
                        user-select: none;
                    }
                }

                .other-images {

                    > .images {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 10px;
                        margin-bottom: 8px;

                        .image-item {
                            position: relative;

                            > .blur-background {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 69px;
                                height: 69px;
                                border-radius: 10px;
                                background-color: #FFFFFF;
                                opacity: 0;
                                z-index: 4;
                                transition: .3s;
                            }

                            .delete-image {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                transition: .3s;
                                opacity: 0;
                                z-index: 5;
                                pointer-events: none;

                                p {
                                    font: normal normal normal 12px/13px Helvetica;
                                    letter-spacing: 0;
                                    color: #212121;
                                }
                            }

                            > img {
                                height: 69px;
                                width: 69px;
                                border-radius: 10px;
                            }
                        }

                        .image-item.blur-on-hover:hover > .blur-background {
                            opacity: .5;
                        }

                        .image-item.blur-on-hover:hover > .delete-image {
                            opacity: 1;
                            pointer-events: auto;
                            cursor: pointer;
                            user-select: none;
                        }
                    }
                }
            }

            .user-voice {
                position: absolute;
                bottom: 20px;
                left: 20px;
            }
        }
    }

    .user-contact-information {
        flex-grow: 1;

        .user-contact-information-content {
            width: 100%;
            height: calc(100% - 34px);
            background: #FFFFFF 0 0 no-repeat padding-box;
            box-shadow: 0 3px 20px #00000029;
            border-radius: 5px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            > .first-row {
                display: flex;
                justify-content: space-between;
            }

            > .second-row {
                display: flex;
                justify-content: space-between;
                margin-top: 25px;

                a {
                    font: normal normal normal 11px/13px Helvetica;
                    letter-spacing: 0;
                    color: #434242;
                }

                button {
                    background: #92EB8E 0 0 no-repeat padding-box;
                    border-radius: 5px;
                    color: #FFFFFF;
                    border: none;
                    font: normal normal bold 10px/12px Helvetica;
                    letter-spacing: 0;
                    padding: 3px 5px;
                }
            }
        }
    }
}
</style>
