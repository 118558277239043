<template lang="pug">
.input-voice
    audio(:src="voiceSrc ? voiceSrc : audioSrc" controls)
    div
        img(src="@/assets/images/plus-circle.svg" v-if="!voiceName" @click="openUpload")
        img.close(src="@/assets/images/close.png" @click="deleteImage" v-else)
        span(@click="openUpload") {{ voiceName || "ვოისის დამატება" }}
    input(ref="input" type="file" accept=".mp3, .wav, .ogg" @change="handleFileUpload" )
</template>

<script>
export default {
    name: "InputVoice",
    emits: ["voice"],
    props: {
        voiceSrc: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            mp3File: null,
            voiceName: null,
            audioSrc: null
        }
    },
    methods: {
        openUpload() {
            if(this.imageName) {
                this.deleteImage()
            } else {
                this.$refs.input.click()
            }
        },
        handleFileUpload(e) {
            this.mp3File = e.target.files[0]
            this.voiceName = e.target.files[0].name
            this.$emit('voice', this.mp3File)
            this.previewAudio();
        },
        previewAudio(){
            let reader = new FileReader();
            reader.readAsDataURL(this.mp3File);
            reader.addEventListener('load', () => {
                this.audioSrc = reader.result;
            });
        },
        deleteImage() {
            this.voiceName = null
            this.mp3File = null
            this.audioSrc = null
            this.$emit('voice', null)
        }
    }
}
</script>

<style lang="scss" scoped>
.input-voice {

    audio {
        margin-bottom: 15px;
    }

    span {
        display: block;
        color: #434242;
        font-size: 12px;
        padding-left: 4px;
    }

    & > span {
        margin-bottom: 8px;
    }

    & > div {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 16px;

        img.close {
            width: 14px;
        }

        span {
            max-width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    & > input {
        display: none;
    }

}
</style>
