<template lang="pug">
.user-parameters
    h2 ᲞᲐᲠᲐᲛᲔᲢᲠᲔᲑᲘ
    .user-parameter-inputs(:class="[editMode && 'edit-mode']")
        base-widget(v-for="data in widgetsData" :data="data" @filterUpdate="val => {this.$emit('userParameters', val)}")
        DefaultInput.user-parameter-input(text="ᲬᲝᲜᲐ" type="number" v-model:value="userWeight")
        DefaultInput.user-parameter-input(text="ᲡᲘᲛᲐᲦᲚᲔ" type="number" v-model:value="userHeight")
        DefaultTextarea.user-biography(text="ბიოგრაფია" placeholderText="მოკლე ბიოგრაფია" v-model:value="description" )
        p {{ errorText }}
    button(@click="changeMode") {{ !editMode ? "ᲠᲔᲓᲐᲥᲢᲘᲠᲔᲑᲐ" : "შენახვა" }}
</template>

<script>
import BaseWidget from "@/components/widgets/BaseWidget";
import DefaultTextarea from "@/components/layout/DefaultTextarea";
import DefaultInput from "@/components/layout/DefaultInput";
import {mapGetters} from "vuex";
export default {
    name: "UserParameters",
    props: {
        widgetsData: {
            type: Array,
            required: true
        },
        errorText: {
            type: String,
            default: null
        }
    },
    components: {
        DefaultInput,
        DefaultTextarea,
        BaseWidget
    },
    data() {
        return {
            editMode: false,
            userWeight: 0,
            userHeight: 0,
            description: ""
        }
    },
    computed: {
        ...mapGetters({
            userBiography: "user/userBiography"
        }),
    },
    watch: {
        userWeight(newVal, oldVal) {
            if(newVal !== oldVal) {
                this.$emit('weight', newVal)
            }
        },
        userHeight(newVal, oldVal) {
            if(newVal !== oldVal) {
                this.$emit('height', newVal)
            }
        },
        description(newVal, oldVal) {
            if(newVal !== oldVal) {
                this.$emit('description', newVal)
            }
        },
        userBiography(val) {
            this.userWeight = val.weight
            this.userHeight = val.height
            this.description = val.description
        }
    },
    methods: {
        changeMode() {
            if(this.editMode) {
                this.$emit('saveData')
            }
            this.editMode=!this.editMode
        }
    }
}
</script>

<style lang="scss" scoped>
.user-parameters {
    height: 100%;
    position: relative;

    h2 {
        font: normal normal bold 16px/19px 'Helvetica-Bold';
        letter-spacing: 0;
        color: #212121;
        margin-bottom: 15px;
    }

    .user-parameter-inputs {
        display: grid;
        grid-template-columns: repeat(2, minmax(160px, 1fr));
        gap: 20px;
        background: #FFFFFF 0 0 no-repeat padding-box;
        box-shadow: 0 3px 20px #00000029;
        border-radius: 5px;
        padding: 20px;
        height: calc(100% - 34px);
        pointer-events: none;

        p {
            color: #F86363;
            font-size: 14px;
            position: absolute;
            bottom: 30px;
            left: 20px;
        }
    }

    button {
        position: absolute;
        bottom: 30px;
        right: 20px;
        border: none;
        background: #92EB8E 0 0 no-repeat padding-box;
        border-radius: 5px;
        color: #FFFFFF;
        font: normal normal bold 10px/12px Helvetica;
        letter-spacing: 0;
        padding: 3px 5px;
        cursor: pointer;
    }

    .user-parameter-inputs.edit-mode {
        pointer-events: auto;
    }
}
</style>

<style lang="scss">
.user-biography {
    width: 100%;

    > span {
        font: normal normal bold 14px/16px 'Helvetica-Bold'!important;
        letter-spacing: 0;
        color: #212121!important;
    }

    > textarea {
        width: 200%!important;
        font: normal normal normal 14px/18px Helvetica!important;
        color: #6E6E6E;
    }
}

.user-parameter-input {
    > span {
        text-align: left;
        font: normal normal bold 14px/16px 'Helvetica-Bold';
        letter-spacing: 0;
        color: #212121;
        text-transform: uppercase;
        opacity: 1;
    }

    .input-wrapper {
        > input {
            font: normal normal normal 14px/18px Helvetica;
        }
    }
}
</style>
