<template lang="pug">
.images-uploader(:class="[disable ? 'disable-images-uploader' : '']")
    .images-uploader-content
        img(src="@/assets/images/plus-circle.svg" @click="openUpload")
        span(@click="openUpload") {{ text }}
        input(ref="input" type="file" @change="getInputImage" accept="image/*" multiple :disabled="disableInput")
    p(v-if="errorMessage" ) {{ errorMessage }}
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "MultipleImageUploader",
    props: {
        text: {
            type: String,
            default: ""
        },
        deleteImageIndex: {
            type: Number,
            default: null
        },
        disable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            image_List: [],
            files: [],
            errorMessage: ""
        }
    },
    computed: {
        ...mapGetters({
            userBiography: "user/userBiography"
        }),
        disableInput() {
            return this.image_List.length === 6 && this.files.length === 6
        }
    },
    watch: {
        deleteImageIndex(newVal) {
            if(newVal !== null) {
                this.image_List.splice(newVal, 1)
                this.files.splice(newVal, 1)
                this.$emit('setDeleteIndex', null)
            }
        }
    },
    methods: {
        openUpload() {
            this.$refs.input.click()
        },
        getInputImage(event) {
            if (this.userBiography.images.length + event.target.files.length > 7) {
                this.errorMessage = "თქვენ არ გაქვთ 6 სურათზე მეტის ატვირთვის საშუალება"
            } else {
                this.errorMessage = ""
                let index = 0
                this.files = []
                while (index < event.target.files.length) {
                    this.files.push(event.target.files[index])
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        this.image_List.push(e.target.result);
                    }
                    reader.readAsDataURL(event.target.files[index]);
                    index++
                }
                this.$emit("update:imagesSrc", this.image_List)
                this.$emit("update:imageFiles", this.files)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.images-uploader {

    & > p {
        color: red;
        margin-top: 5px;
        font-size: 12px;
    }

    & > .images-uploader-content {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        & > span {
            display: block;
            color: #434242;
            font-size: 12px;
            user-select: none;
        }

        & > input {
            display: none;
        }
    }
}

.images-uploader.disable-images-uploader {
    opacity: .5;
    pointer-events: none;

}
</style>
